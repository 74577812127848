import React from 'react';
import "../assets/Footer.css"; // Tu peux styliser le footer ici

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            <p>&copy; {currentYear} - Jean-pierre Decloedt Tous droits réservés</p>
        </footer>
    );
};

export default Footer;