import React from 'react';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import "./App.css"


function App() {
  return (
      <div>
        <header className={"header"}>
            <img src="./jean-pierre-ok.png" alt="logo"/>
        </header>
        <Gallery />

          <Footer />
      </div>
  );
}

export default App;
