import React, { useState, useEffect } from 'react';
import "../assets/Gallery.css"; // Style CSSnpm start

const Gallery = () => {
    const [folders, setFolders] = useState([]);
    const folderId = '1zI1yBpizb635kbsbtd803dTsXt3h7ttP'; // L'ID du dossier parent sur Google Drive
    const apiKey = process.env.REACT_APP_API_KEY;// Ta nouvelle clé API Google

    const saveImageToCache = (folderId, thumbnailId) => {
        const cachedThumbnails = JSON.parse(localStorage.getItem('cachedThumbnails')) || {};
        cachedThumbnails[folderId] = thumbnailId;
        localStorage.setItem('cachedThumbnails', JSON.stringify(cachedThumbnails));
    };

    const getImageFromCache = (folderId) => {
        const cachedThumbnails = JSON.parse(localStorage.getItem('cachedThumbnails')) || {};
        return cachedThumbnails[folderId] || null;
    };

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const response = await fetch(
                    `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents+and+mimeType='application/vnd.google-apps.folder'&key=${apiKey}&fields=files(id,name)`
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.error) {
                    console.error("Erreur lors de la récupération des dossiers:", data.error);
                    return;
                }

                const foldersWithImages = [];

                for (const folder of data.files) {
                    try {
                        const cachedThumbnailId = getImageFromCache(folder.id);
                        if (cachedThumbnailId) {
                            foldersWithImages.push({
                                folderId: folder.id,
                                folderName: folder.name,
                                thumbnailId: cachedThumbnailId,
                                thumbnailName: 'Cached Thumbnail',
                            });
                        } else {
                            const imageResponse = await fetch(
                                `https://www.googleapis.com/drive/v3/files?q='${folder.id}'+in+parents+and+mimeType='image/jpeg'&key=${apiKey}&fields=files(id,name)`
                            );

                            if (!imageResponse.ok) {
                                throw new Error(`HTTP error! status: ${imageResponse.status}`);
                            }

                            const imageData = await imageResponse.json();
                            const thumbnail = imageData.files.length > 1 ? imageData.files[5] : null;

                            if (thumbnail) {
                                saveImageToCache(folder.id, thumbnail.id);
                            }

                            foldersWithImages.push({
                                folderId: folder.id,
                                folderName: folder.name,
                                thumbnailId: thumbnail ? thumbnail.id : null,
                                thumbnailName: thumbnail ? thumbnail.name : null,
                            });
                        }
                    } catch (error) {
                        console.error(`Erreur lors de la récupération des fichiers pour le dossier ${folder.name}:`, error);
                        foldersWithImages.push({
                            folderId: folder.id,
                            folderName: folder.name,
                            thumbnailId: null,  // Pas d'image
                            thumbnailName: null,
                        });
                    }
                }

                setFolders(foldersWithImages);
            } catch (error) {
                console.error("Erreur lors de la récupération des dossiers:", error);
                setFolders([{ folderId: null, folderName: "Erreur", thumbnailId: null, thumbnailName: null }]);
            }
        };

        fetchFolders();
    }, [folderId, apiKey]);

    return (
        <div className={"gallery"}>
            {folders.map((folder) => (
                <div key={folder.folderId || Math.random()} className={"folder"}>
                    <a href={folder.folderId ? `https://drive.google.com/drive/folders/${folder.folderId}` : "#"} target="_blank" rel="noopener noreferrer">
                        <img
                            src={folder.thumbnailId
                                ? `https://lh3.googleusercontent.com/d/${folder.thumbnailId}=s220?a`
                                : `https://via.placeholder.com/300x200?text=${encodeURIComponent(folder.folderName)}`}
                            alt={folder.thumbnailName || folder.folderName}
                            className={"image"}
                            onError={(e) => { e.target.src = `https://via.placeholder.com/300x200?text=${encodeURIComponent(folder.folderName)}` }}
                        />
                    </a>
                    <p className={"caption"}>{folder.folderName}</p>
                </div>
            ))}
        </div>
    );
};

export default Gallery;
